import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";
import {
  initializeAuth,
  browserLocalPersistence,
  indexedDBLocalPersistence,
} from "firebase/auth";
// import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_Measurement_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
// export const db = getFirestore(firebaseApp);
// export const auth = getAuth(firebaseApp);
// choose initializeAuth because getAuth cause to load a file called "iframe.js" on mobile
// https://github.com/firebase/firebase-js-sdk/issues/4946
// https://stackoverflow.com/questions/68692470/firebase-v9-loads-a-large-iframe-js-263k-on-mobile
export const auth = initializeAuth(firebaseApp, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence],
});
// export const storage = getStorage(firebaseApp);
// export const auth = firebaseApp.auth();

export default firebaseApp;
